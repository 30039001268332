<template>
  <section class="my-50 lg:my-100">
    <div class="container">
      <div class="pr-60">
        <h3
          class="wysiwyg-title text-3xl md:text-4xl text-secondary"
          v-html="heading"
        />
        <NuxtLink
          v-if="button?.url"
          :to="button?.url"
          :target="button?.target"
          class="inline-flex mt-10 items-center text-xl font-serif underline space-x-5 group"
          external
        >
          <span>{{ button?.text }}</span>
          <chevronIcon class="w-20 -rotate-90 group-hover:translate-x-5 transition" />
        </NuxtLink>
      </div>

      <Swiper
        v-if="products.length"
        slides-per-view="auto"
        navigation
        :modules="[SwiperNavigation]"
        class="mt-25 [&.swiper]:overflow-visible"
      >
        <SwiperSlide
          v-for="(product, productIndex) in products"
          :key="`product-${product.id}`"
          class="mr-25 last:mr-0"
        >
          <product
            :product="product"
            :gtm-data="gtmData"
            :index="productIndex"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<script setup>
import {
  useNuxtApp, onMounted, useDataLayer, SwiperNavigation,
} from '#imports';

// General icons
import chevronIcon from '@/assets/img/icon-chevron.svg';

// Props
const props = defineProps({
  heading: {
    type: String,
    required: true,
  },
  button: {
    type: Object,
    required: false,
    default: null,
  },
  products: {
    type: Array,
    required: false,
    default: () => ([]),
  },
  gtmData: {
    type: Object,
    default: () => ({}),
  },
});

// Data
const { $gtm } = useNuxtApp();
const { item } = useDataLayer();

// Hooks
onMounted(() => {
  /* c8 ignore start */
  $gtm.trackEvent({ ecommerce: null });
  $gtm.trackEvent({
    event: 'view_item_list',
    ecommerce: {
      items: props.products.map((product, index) => item({ product, index, data: props.gtmData })),
    },
  });
  /* c8 ignore stop */
});
</script>
